<template>
  <v-dialog
      max-width="800"
      :value="display"
      @input="onCloseDialog"
      @click:outside="onCloseDialog"
  >
    <v-card :loading="loading">
      <v-card-title>
        {{ dialogTitle }}
        <v-spacer/>
        <v-icon @click="onCloseDialog">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <v-form
            ref="creationForm"
            v-model="valid"
            lazy-validation
            class="dialog-create-update"
        >
          <v-select
              v-model="newUser.groups"
              :items="groupsLocale"
              :disabled="checkStatus('MED') || isUpdate"
              :label="$t('profile.inputs.profile')"
              :rules="[rules.groups]"
              multiple
              outlined
              class="dialog-create-update"
          />
          <v-select
              v-model="newUser.referent"
              v-if="hasReferent"
              :items="referentsLocale"
              :disabled="checkStatus('MED') || (isUpdate && isPatientCreationUpdate)"
              :label="$t('profile.inputs.referent')"
              outlined
              class="dialog-create-update"
          />
          <v-select
              v-model="newUser.region"
              v-if="inputDisplay && !regionNotToDisplay"
              :items="regionsLocale"
              :disabled="isUpdate && isPatientCreationUpdate"
              :label="$t('profile.inputs.region')"
              outlined
              class="dialog-create-update"
          />
          <v-select
              v-model="newUser.studies"
              v-if="inputDisplay || isPatientCreationUpdate"
              :items="studiesLocale"
              :label="$t('profile.inputs.study')"
              :multiple="!isPatientCreationUpdate || !checkStatus('TEC')"
              outlined
              class="dialog-create-update"
          />
          <v-text-field
              v-model="newUser.username"
              :label="$t('profile.inputs.username')"
              :disabled="isUpdate"
              outlined
              v-if="inputDisplay"
              :rules="[rules.allowedCharacters]"
              class="dialog-create-update"
          />
          <v-text-field
              v-model="newUser.firstName"
              :label="$t('profile.inputs.firstName')"
              outlined
              v-if="inputDisplay"
              :rules="[rules.firstNameRequired]"
              class="dialog-create-update"
          />
          <v-text-field
              v-model="newUser.lastName"
              :label="$t('profile.inputs.lastName')"
              outlined
              v-if="inputDisplay"
              :rules="[rules.lastNameRequired]"
              class="dialog-create-update"
          />
          <v-text-field
              type="email"
              v-model="newUser.email"
              :label="$t('profile.inputs.mail')"
              outlined
              v-if="(inputDisplay || isPatientCreationUpdate) && !checkStatus('CHR')"
              :rules="[rules.emailRequired]"
              class="dialog-create-update"
          />
          <input-phone-number
              v-model="newUser.phone"
              :label="$t('profile.inputs.phone')"
              v-if="inputDisplay || isPatientCreationUpdate"
              class="dialog-create-update"
          />
          <v-switch
              v-model="newUser.included"
              :label="patientIncluded"
              v-if="isPatientCreationUpdate"
              class="dialog-create-update"
          >
          </v-switch>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn type="reset" @click.prevent="onCloseDialog" text>
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <v-btn
            type="submit"
            @click.prevent="onCreateUser"
            text
            color="primary"
            v-if="!isUpdate"
        >
          {{ $t('general.buttons.create') }}
        </v-btn>
        <v-btn
            type="submit"
            @click.prevent="onUpdateUser"
            text
            color="primary"
            v-else
        >
          {{ $t('general.buttons.update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { myStatus, groupCreationRights } from '@/store/modules/User'
import { JWTTranslation } from '@/store/modules/Security'
import InputPhoneNumber from '@/components/Template/InputPhoneNumber'

export default {
  name: 'DialogCreationUpdate',

  components: { InputPhoneNumber },

  props: {
    display: {
      required: true,
      type: Boolean,
    },
    isUpdate: {
      required: false,
      type: Boolean,
      default: false,
    },
    referentType: {
      required: false,
      type: String,
    },
    user: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      referentsLocale: [],
      groupsLocale: [],
      loading: false,
      newUser: {
        id: null,
        groups: [],
        referent: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        region: null,
        username: '',
        studies: [],
        included: false,
      },
      regionsLocale: [],
      rules: {
        groups: v => {
          if (v.length === 0 || v.length === 1) {
            return true
          } else if (
              v.length >= 3 ||
              (v.length === 2 &&
                  !v.every(group => this.groupsCanIsMultiple.includes(group)))
          ) {
            return this.$t('user.creationUpdate.errors.association')
          } else {
            return true
          }
        },
        firstNameRequired: v => {
          return !v
                 ? this.$t('user.creationUpdate.errors.firstNameRequired')
                 : true
        },
        lastNameRequired: v => {
          return !v
                 ? this.$t('user.creationUpdate.errors.lastNameRequired')
                 : true
        },
        emailRequired: v => {
          return !v ? this.$t('user.creationUpdate.errors.emailRequired') : true
        },
        allowedCharacters: v => {
          return /^[\w@+.-]+$/.test(v)
        },
      },
      studiesLocale: [],
      valid: false,
    }
  },

  computed: {
    ...mapGetters(['groups', 'regions', 'studies', 'users', 'userConnected']),
    dialogTitle () {
      return this.isUpdate
             ? this.$t('user.creationUpdate.PAT.dialogTitleUpdate', {
            username: this.user.username,
          })
             : this.$t('user.creationUpdate.PAT.dialogTitle')
    },
    groupsCanIsMultiple () {
      return this.groups
                 .filter(group => group.name === 'MED' || group.name === 'CHR')
                 .map(group => group.id)
    },
    hasReferent () {
      return (
          this.newUser.groups.length !== 0 &&
          this.newUser.groups.every(userGroup => {
            return this.groups
                       .filter(group => ['PAT', 'MED', 'CHR'].includes(group.name))
                       .map(group => group.id)
                       .includes(userGroup)
          })
      )
    },
    inputDisplay () {
      return (
          this.newUser.groups.length !== 0 &&
          !this.isPatientCreationUpdate &&
          (this.isUpdate || this.$refs.creationForm)
      )
    },
    isPatientCreationUpdate () {
      const group = this.groups.find(group => group.name === 'PAT')
      return group ? this.newUser.groups.includes(group.id) : false
    },
    regionNotToDisplay () {
      return (
          this.newUser.groups.length !== 0 &&
          this.newUser.groups.every(userGroup => {
            return this.groups
                       .filter(group => ['TEC', 'CHR'].includes(group.name))
                       .map(group => group.id)
                       .includes(userGroup)
          })
      )
    },
    patientIncluded () {
      return this.newUser.included
             ? this.$t('profile.inputs.included')
             : this.$t('profile.inputs.notIncluded')
    },
  },

  watch: {
    display () {
      if (this.isUpdate) {
        this.loading = true
        this.newUser = {
          id: this.user.id,
          groups: this.user.groupsId,
          referent: this.user.referentId,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          region: this.user.regionId,
          username: this.user.username,
          studies: this.user.studies,
          included: this.user.included,
        }
      } else {
        if (myStatus(['MED']) && this.newUser.groups.length === 0) {
          this.newUser.groups.push(
              this.groups.find(group => group.name === 'PAT').id,
          )
          this.updateReferentList('MED')
        }
        if (
            this.userConnected.studySelected &&
            'id' in this.userConnected.studySelected &&
            this.newUser.studies.length === 0
        ) {
          this.newUser.studies.push(this.userConnected.studySelected.id)
        }
      }

      this.loading = false
    },
    newUser: {
      deep: true,
      handler ({ groups }) {
        if (this.$refs.creationForm && this.$refs.creationForm.validate()) {
          this.referentsLocale.splice(0, this.referentsLocale.length)
          if (
              groups.every(group => {
                return this.groups
                           .filter(groupSaved => ['MED', 'CHR'].includes(groupSaved.name))
                           .map(groupSaved => groupSaved.id)
                           .includes(group)
              })
          ) {
            this.updateReferentList('TEC')
          } else if (
              this.groups.find(group => group.name === 'PAT').id === groups[0]
          ) {
            this.updateReferentList('MED')
          }
        }
      },
    },
  },

  created () {
    this.loading = true
    Promise.all([
      this.loadGroups(),
      this.loadRegions(),
      this.loadUsers({ groupName: this.referentType }),
      this.loadStudies(),
    ])
           .then(() => {
             this.regions.forEach(region => {
               this.regionsLocale.push({
                 value: region.code,
                 text: region.label,
               })
             })
             this.studies.forEach(study => {
               this.studiesLocale.push({
                 value: study.id,
                 text: study.label,
               })
             })
             this.groups.forEach(group => {
               if (groupCreationRights()
                   .includes(group.name)) {
                 this.groupsLocale.push({
                   value: group.id,
                   text: group.humanName,
                 })
               }
             })

             this.referentsLocale.splice(0, this.referentsLocale.length)

             // TODO: Refactor & optimize !!!!
             if (myStatus(['MED'])) {
               this.newUser.groups.push(
                   this.groups.find(group => group.name === 'PAT').id,
               )

               const doctor = JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
               this.newUser.referent = doctor.userId
               this.referentsLocale.push({
                 value: doctor.userId,
                 text: doctor.firstName + ' ' + doctor.lastName,
               })
               if (
                   this.userConnected.studySelected &&
                   'id' in this.userConnected.studySelected
               ) {
                 this.studiesLocale.splice(0, this.studiesLocale.length)
                 this.studies
                     .filter(study => study.id === this.userConnected.studySelected.id)
                     .forEach(study => {
                       this.studiesLocale.push({
                         value: study.id,
                         text: study.label,
                       })
                     })
               }
             } else if (myStatus(['TEC'])) {
               const tec = JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
               this.referentsLocale.push({
                 value: tec.userId,
                 text: tec.firstName + ' ' + tec.lastName,
               })
               this.studiesLocale.splice(0, this.studiesLocale.length)
               this.studies
                   .filter(study => study.id === this.userConnected.studySelected.id)
                   .forEach(study => {
                     this.studiesLocale.push({
                       value: study.id,
                       text: study.label,
                     })
                   })
               this.updateReferentList('TEC')
             } else if (myStatus(['ADM'])) {
               this.updateReferentList('TEC')
               //this.updateReferentList('MED')
             } else if (myStatus(['CHR'])) {
               this.updateReferentList('MED')
             }
             this.loading = false
           })
  },

  methods: {
    ...mapActions([
      'createUser',
      'displaySnackbar',
      'loadGroups',
      'loadRegions',
      'loadStudies',
      'loadUsers',
      'updateUser',
    ]),
    checkStatus (status) {
      return myStatus([status])
    },
    onCloseDialog () {
      this.newUser = {
        id: null,
        groups: [],
        referent: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        region: null,
        username: '',
        studies: [],
        included: false,
      }
      this.$emit('update:display', false)
      this.$emit('close-dialog')
    },
    onCreateUser () {
      const { firstName, lastName, ...rest } = this.newUser

      const newUser = this.isPatientCreationUpdate
                      ? { ...rest }
                      : { first_name: firstName, last_name: lastName, ...rest }

      if (this.$refs.creationForm.validate()) {
        this.loading = true

        this.createUser(newUser)
            .then(this.requestOk)
            .catch(this.requestError)
      }
    },
    onUpdateUser () {
      let data = null
      const { phone, email, studies, region, included } = this.newUser

      if (this.isPatientCreationUpdate) {
        data = { phone, email, studies, region, included }
      } else {
        const { firstName, lastName, referent, groups } = this.newUser
        data = {
          referent,
          phone,
          email,
          last_name: lastName,
          first_name: firstName,
          studies,
          region,
          groups,
        }
      }
      if (this.$refs.creationForm.validate()) {
        this.updateUser({ data, user: this.newUser })
            .then(this.requestOk)
            .catch(this.requestError)
      }
    },
    requestOk ({ data } = {}) {
      this.onCloseDialog()
      this.loading = false
      this.$emit('form-submit')

      let snackbar_text = ''

      if (!this.isUpdate) {
        snackbar_text = this.$t('user.creationUpdate.success.created', {
          username: data.username,
        })
      } else {
        snackbar_text = this.$t('user.creationUpdate.success.updated', {
          username: this.user.username,
        })
      }

      this.displaySnackbar({
        status: true,
        type: 'success',
        text: snackbar_text,
      })
    },
    requestError ({ response }) {
      this.displaySnackbar({
        status: true,
        type: 'error',
        text: response.data.errors.reduce((acc, cur, i) => {
          if (i !== 0) acc += ' & '
          acc += cur
          return acc
        }),
      })
      this.loading = false
    },
    /**
     * Update the referent list when new user group updated
     *
     * @param {String} referentStatus - values : 'MED' 'TEC'
     */
    updateReferentList (referentStatus) {
      this.referentsLocale.splice(0, this.referentsLocale.length)

      // if user status is TEC load TECs and MEDs
      if (referentStatus === 'TEC' && myStatus(['TEC'])) {
        if (
            this.newUser.groups.every(group => {
              return this.groups
                         .filter(groupSaved => ['MED', 'CHR'].includes(groupSaved.name))
                         .map(groupSaved => groupSaved.id)
                         .includes(group)
            })
        ) {
          const tec = JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
          this.referentsLocale.push({
            value: tec.userId,
            text: tec.lastName + ' ' + tec.firstName,
          })
          this.newUser.referent = tec.userId
        } else {
          this.users
              .filter(
                  user =>
                      user.groups.find(
                          group => (group.name === 'TEC') !== undefined,
                      ) ||
                      user.groups.find(group => (group.name === 'MED') !== undefined),
              )
              .forEach(referent => {
                this.referentsLocale.push({
                  value: referent.id,
                  text: `${referent.lastName} ${referent.firstName}`,
                })
              })
          const tec = JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
          this.referentsLocale.push({
            value: tec.userId,
            text: tec.lastName + ' ' + tec.firstName,
          })
        }
        // if user is MED the med only
      } else if (referentStatus === 'MED' && myStatus(['MED'])) {
        const doctor = JWTTranslation(localStorage.getItem('JWT_ACCESS')).body
        this.newUser.referent = doctor.userId
        this.referentsLocale.push({
          value: doctor.userId,
          text: doctor.lastName + ' ' + doctor.firstName,
        })
      } else {
        this.users
            .filter(
                user =>
                    user.groups.find(group => group.name === referentStatus) !==
                    undefined,
            )
            .forEach(referent => {
              this.referentsLocale.push({
                value: referent.id,
                text: `${referent.lastName} ${referent.firstName}`,
              })
            })
      }

      this.referentsLocale.sort((referentA, referentB) => {
        const [lastNameA] = referentA.text.split(' ')
        const [lastNameB] = referentB.text.split(' ')

        for (let i = 0; i < Math.min(lastNameA.length, lastNameB.length); i++) {
          if (lastNameA[i] < lastNameB[i]) {
            return -1
          } else if (lastNameA[i] > lastNameB[i]) {
            return 1
          }
          return 0
        }
        return 0
      })
    },
  },
}
</script>
