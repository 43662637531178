<template>
  <v-text-field
    :value.sync="value"
    v-bind="$attrs"
    ref="phoneNumber"
    @input="formatPhone($event)"
    outlined
    type="phone"
  />
</template>
<script>
import { AsYouType } from 'libphonenumber-js'

export default {
  name: 'InputPhoneNumber',

  props: ['value'],

  methods: {
    formatPhone(value) {
      // TODO: Make language code dynamic ?
      this.$emit('input', new AsYouType('FR').input(value))
    },
  },
}
</script>
